import { useAppSelector } from "redux/hooks";
import UpdateCategoryStatusForm from "components/templates/UpdateCategoryStatusForm";
import CategoryService from "services/category.service";
import { useState } from "react";
import CircleLoader from "components/atoms/CircleLoader";

export default function UpdateCategoryStatus() {
  const [loading, setLoading] = useState(false)
  const { _id ,type} = useAppSelector((state) => state.modal?.data);

  const handleSubmit = async (values: any) => {
    setLoading(true)
    await CategoryService.updateCategoryStatus(_id, values);
    setLoading(false)

  };
  return (
    <div>
      {loading&&<CircleLoader />}
      <h3>Update {type||'Category'} Status</h3>
      <UpdateCategoryStatusForm  onSubmit={handleSubmit} />
    </div>
  );
}
