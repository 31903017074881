import http from "./http.service";
import Promisable from "./promisable.service";
import { NavigateFunction } from "react-router-dom";
import { loaderActions } from "redux/slices/loader";
import { getAppDispatch } from "utils/dispatch.util";
import { formLoaderActions } from "redux/slices/formLoader";
import { reviewActions } from "redux/slices/review";
import { modalActions } from "redux/slices/modal";
import ImageService from "./image.service";

const url = "admin/listing";

const ReviewService = {
  addReview: async (id:any,data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();
    if (data.image) {
      data.image = await ImageService.uploadFile(data.image);
     
    }
    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/add-review/${id}`, data)
    );

    if (success) {
      // const { review } = success?.data?.data||{};
      console.log("success",success)

      // dispatch?.(reviewActions.addReview(review));
      navigate?.(`/listing-details/${id}`);
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
 
  getReview: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(loaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/review/${id}`)
    );

    if (success) {
      const { review } = success?.data?.data||{};
      dispatch?.(reviewActions.setReview(review));
    } else dispatch?.(reviewActions.setReview({ data: "Not Found" }));

    dispatch?.(loaderActions.setLoading(false));
    return [success, error];
  },
  getReviews: async (data:any,type:any) => {
    const dispatch = getAppDispatch();
    dispatch?.(reviewActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/review?type=${type}`,data)
    );

    if (success) {
      const { reviews,count } = success?.data?.data||{};
      dispatch?.(reviewActions.setReviews({ reviews,count }));
    }

    dispatch?.(reviewActions.setLoading(false));
    return [success, error];
  },

  updateReviewStatus: async (id: any, data: any, navigate?: NavigateFunction) => {

    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/review/${id}`, data)
    );
   

    if (success) {

      const { review } = success?.data?.data||{};
      dispatch?.(modalActions.closeModal());

      dispatch?.(reviewActions.updateReviewStatus({ id, currentStatus:data.currentStatus }));
      // getReviews()
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
}; 

export default ReviewService;
