import Button from "components/atoms/Button";
import { modalActions } from "redux/slices/modal";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import Input from "components/atoms/Input";
import { useState, useEffect } from "react";
import CertificateService from "services/certificate.service";

export default function SendEmail() {
  const [email, setEmail] = useState("");
  useEffect(() => {}, []);

  const dispatch = useAppDispatch();

  const data = useAppSelector((state) => state.modal.data);
  useEffect(() => {
    if (data.email) {
      setEmail(data.email);
    }
  }, [data]);
  const onClickYes = () => {
    if (email && data.url) {
      CertificateService.sendCertificate({
        listingTitle: data?.listingTitle,
        email,
        url: data.url,
      });
    }
  };

  return (
    <div>
      <h3>Certificate will be send on below email</h3>

      <form
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        <div>
          <Input
            value={email}
            label="Email"
            required
			type="email"
            onChange={(event) => setEmail(event.target.value)}
          />
        </div>
        <br />
        <Button
          variant="outlined"
          sx={{ marginRight: "10px" }}
          onClick={() => dispatch(modalActions.closeModal())}
        >
          No
        </Button>
        <Button variant="contained" type="submit" onClick={onClickYes}>
          Send
        </Button>
      </form>
    </div>
  );
}
