import { Grid } from "@mui/material";
import FileUploadRedux from "components/molecules/FileUploadRedux";
import { config } from "config";
import { useEffect, useState } from "react";
import { change } from "redux-form";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { modalActions } from "redux/slices/modal";
import ImageService from "services/image.service";

export default function IconGallery() {
  const data = useAppSelector((state) => state.modal.data);
  const [list, setList] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  async function getList() {
    dispatch(modalActions.setLoading(true));
    let res = await ImageService.getIcons('highlight');
    if (res?.length > 0) setList(res);
    dispatch(modalActions.setLoading(false));
  }
  useEffect(() => {
    getList();
    return () => {
      setList([]);
    };
  }, []);
  return (
    <div>
      <h3>HighLight Icons</h3>
      <Grid container spacing={2}>
        {list?.map((item: any, index: any) =>(
          <Grid item md={3} lg={3} sx={{ marginBottom: "14px" }} key={index}>
            <button
              type="button"
              onClick={() => {
                dispatch(change("AddListingForm", data, item));
                dispatch(modalActions.closeModal());
              }}
              style={{
                borderRadius: "0px 0px 0px 5px",
                padding: "4px",
                border: "none",
                cursor: "pointer",
                background: "#d7d7d745",
                height: "100px",
                width: "100px",
              }}
            >
              <img
                src={config.API_URL + item}
                alt="icons"
                style={{ width: "100%", height: "100%" }}
              />
            </button>
          </Grid>
        ))}

        <Grid item md={3} lg={3} sx={{ marginBottom: "14px" }}>
          <FileUploadRedux
            height={"100px"}
            width={"100px"}
            title={"upload"}
            folder={"icons"}
            FileUploadProps={{
              maxSize: 5,
              accept: [".jpg", ".jpeg", ".png"],
            }}
            input={{
              onChange: (event: any) => {
                setList((prev: any) => [...prev, event]);
              },
            }}
            setNull={true}
          />
        </Grid>
      </Grid>

      {/* <FileUpload onChange={(event:any)=>console.log("eve",event)} height={"100px"} width={"100px"} title={'upload'}/> */}
    </div>
  );
}
