import { config } from "config";
import {
  ContactLoadingPayload,
  ContactState,
  SetContactPayload,
  SetContactsPayload,
} from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const default_page_size = config.PAGE_SIZE;
const initialContact = {
  contacts: [],
  loading: true,
};

const initialState: ContactState = {
  tab: 0,
  contact: null,
  loading: true,
  open: initialContact,
  close: initialContact,
  count: {open:0,close:0},
  refresh: 0,
  current_filters: {},
  refreshLoader: false,
  contactOptions: [],

  filters: { page: 1, pageSize: default_page_size },
};

export const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    setTab: (state, action: PayloadAction<number>) => {
      state.tab = action.payload;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setLoading: (state, action: PayloadAction<ContactLoadingPayload>) => {
      const { type, loading } = action.payload;
      state[type].loading = loading;
    },
    setContacts: (state, action: PayloadAction<SetContactsPayload>) => {
      const { type, contacts ,totalCount} = action.payload;
      state.refreshLoader = false;
      state.count[type]=totalCount

      state[type].contacts = contacts;
    },
    deleteContactRequestById: (
      state,
      action: PayloadAction<SetContactPayload>
    ) => {
      const { type, contact } = action.payload;

      if (!contact?._id) return;

      state[type].contacts = state[type].contacts.filter(
        ({ _id }) => _id !== contact._id
      );
    },

    setPage: (state, action: PayloadAction<number>) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters.page = action.payload;
    },
    refresh: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
      state.refreshLoader = true;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
  },
});

const contactReducer = contactSlice.reducer;

export const contactActions = contactSlice.actions;
export default contactReducer;
