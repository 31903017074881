import Input from "../Input";
import { FileUploadOwnProps } from ".";
import ToasterService from "utils/toaster.util";
import InputLabel from "@mui/material/InputLabel";
import { Box } from "@mui/material";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import { useEffect, useState } from "react";
import { config } from "config";
import CircleLoader from "../CircleLoader";
export type FileUploadProps = FileUploadOwnProps &
  React.ComponentProps<typeof Input>;

export default function FileUpload({
  value,
  accept,
  maxSize,
  onChange,
  loading,
  sx,
  uploadIcon=true,
  ...rest
}: FileUploadProps & { onChange: any; rest?: any; loading?: boolean }) {
  const [Preview, serPreview] = useState<any>(null);

  useEffect(() => {
    if (value && typeof value === "string") {
      serPreview(`${config.API_URL}${value}`);
    }else if(rest.defaultImage){
      serPreview(rest.defaultImage)
    }
  }, [value]);

  let setNull = rest?.setNull;
  let isLoading = !loading;

  useEffect(() => {
    if (isLoading && Preview && setNull) {
      serPreview(null);
    }
  }, [isLoading, Preview, setNull]);
  return (
    <Box>
      <InputLabel
        htmlFor={rest.id}
        sx={{
          cursor: "pointer",
          width: rest.width || "100%",
          height: rest.height || "273px",
          background: "#fafafa",
          borderRadius: "4px",
          position: "relative",
          ...sx,
        }}
      >
       {uploadIcon&& <Box
          sx={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "0px 0px 0px 5px",
            padding: "4px",
            background: "#fafafa4d",
            top: "0",
            right: "0",
            flexDirection: "column",
            zIndex: 0,
          }}
        >
          <AddPhotoAlternateOutlinedIcon />
        </Box>}
        {Preview && (
          <img src={Preview} alt="" style={{ height: "100%", width: "100%" }} />
        )}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            fontWeight: 500,
            flexDirection: "column",
          }}
        >
          <div>{rest.title || "Upload Logo"}</div>
        </Box>

        {loading && <CircleLoader />}
        <div hidden>
          <Input
            {...rest}
            type="file"
            id={rest.id}
            hidden
            inputProps={{ accept, ...rest.inputProps }}
            InputLabelProps={{ shrink: true, ...rest.InputLabelProps }}
            onChange={(e: any) => {
              let file = e.target.files?.[0];
              if (!file) return onChange?.(file, rest.name);
              if (accept) {
                let types = file.name.split(".");
                let type = types[types.length - 1].toLowerCase();
                if (!accept.includes(`.${type}`)) {
                  ToasterService.showError(`${type} not allowed!`);
                  let error: any = { error: `${type} not allowed!` };
                  return onChange?.(error);
                }
              }
              if (maxSize) {
                let size = maxSize * 1024 * 1024;
                if (file.size > size) {
                  ToasterService.showError(`Max file size is ${maxSize} MB`);
                  let error: any = { error: `Max file size is ${maxSize} MB` };
                  return onChange?.(error);
                }
              }
              serPreview(URL.createObjectURL(file));
              onChange?.(file);
            }}
          />
        </div>
      </InputLabel>
    </Box>
  );
}
