import http from "./http.service";
import Promisable from "./promisable.service";
import { NavigateFunction } from "react-router-dom";
import { loaderActions } from "redux/slices/loader";
import { getAppDispatch } from "utils/dispatch.util";
import { formLoaderActions } from "redux/slices/formLoader";
import { categoryActions } from "redux/slices/category";
import { modalActions } from "redux/slices/modal";
import ToasterService from "utils/toaster.util";

const url = "admin/categories";

const CategoryService = {
  addCategory: async (data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}`, data)
    );

    if (success) {
      const { category } = success?.data?.data || {};
      dispatch?.(categoryActions.addCategory(category));
      dispatch?.(modalActions.closeModal());
      ToasterService.showSuccess(`New Category as been added`);
    }

    dispatch?.(modalActions.setLoading(false));

    return [success, error];
  },
  updateCategory: async (
    id: string,
    data: any,
    navigate?: NavigateFunction
  ) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.patch(`${url}/${id}`, data)
    );

    if (success) {
      const { category } = success?.data?.data || {};
      dispatch?.(categoryActions.updateCategory({ id, category }));
      navigate?.("/categories");
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
  updateCategoryStatus: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.patch(`${url}/${id}`, data)
    );

    if (success) {
      const { category } = success?.data?.data || {};
      dispatch?.(categoryActions.updateCategory({ id, category }));
      ToasterService.showSuccess("Category status updated successfully")
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
  getCategory: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(loaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const { category } = success?.data?.data || {};
      dispatch?.(categoryActions.setCategory(category));
    } else dispatch?.(categoryActions.setCategory({ data: "Not Found" }));

    dispatch?.(loaderActions.setLoading(false));
    return [success, error];
  },
  getCategories: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(categoryActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}`)
    );

    if (success) {
      const { categories } = success?.data?.data || {};
      dispatch?.(categoryActions.setCategories(categories));
    }

    dispatch?.(categoryActions.setLoading(false));
    return [success, error];
  },
};

export default CategoryService;
