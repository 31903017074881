import { useAppSelector } from "redux/hooks";

export default function DisplayIframe() {
  const { title ,iframe} = useAppSelector((state) => state.modal?.data);

  return (
    <div>
      <h3>{title}</h3>
      <div style={{width:"100%",height:"100%"}} dangerouslySetInnerHTML={{ __html: iframe }} />
    </div>
  );
}
