import { useAppSelector } from "redux/hooks";
import ListingService from "services/listings.service";
import UpdateListingRankForm from "components/templates/UpdateListingRankForm";

export default function UpdateListingRank() {
  const { _id ,type,status} = useAppSelector((state) => state.modal?.data);
  const handleSubmit = async (values: any) => {
    let {rank,rankPage} = values
    let data:any={}
    if(type==='featureRank') data['featureRank']=rank||null
    else {
      data['rank']=rank||null
      data['rankPage']=rankPage||null
    }
      ListingService.updateListingRank(_id, data);
   
  };
  return (
    <div>
      <h3>Update {type==='featureRank'&&"Featured"} Listing Rank</h3>
      {status=='pending'?<div style={{paddingTop:"10px",fontWeight:500,color:'red',display:"flex",justifyContent:"center",alignItems:"center"}}>Please Approve the Listing Before Setting the Rank</div>:<UpdateListingRankForm  onSubmit={handleSubmit} />}
      
    </div>
  );
}
