import { CategoryState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: CategoryState = {
	categories: [],
	category: null,
	loading: true,
	categoryOptions: [],
};

export const categorySlice = createSlice({
	name: "category",
	initialState,
	reducers: {
		clear: (state) => {
			state.categoryOptions = [];
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		addCategory: (state, action) => {
			state.categories.unshift(action.payload);
		},
		updateCategory: (state, action) => {
			const { id, category } = action.payload;
			state.categories.every(({ _id }, i) => {
				if (id === _id) {
					state.categories[i] = category;
					return false;
				}
				return true;
			});
		},
		setCategory: (state, action) => {
			state.category = action.payload;
		},
		setCategories: (state, action) => {
			const categories= action.payload;
			state.categories = categories;
		},
	},
});

const categoryReducer = categorySlice.reducer;

export const categoryActions = categorySlice.actions;
export default categoryReducer;