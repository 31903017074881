import { config } from "config";
import { ListingState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const default_page_size = config.PAGE_SIZE;

const initialState: ListingState = {
  count: 0,
  listings: [],
  listing: null,
  loading: true,
  listingOptions: [],
  refresh: 0,
  current_filters: {},
  refreshLoader: false,
  filters: { page: 1, pageSize: default_page_size },
};

export const listingSlice = createSlice({
  name: "listing",
  initialState,
  reducers: {
    clear: (state) => {
      state.listingOptions = [];
    },
    setOptions: (state, action: PayloadAction<any[]>) => {
      state.listingOptions = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addListing: (state, action) => {
      state.listings.unshift(action.payload);
    },
    updateListing: (state, action) => {
      const { id, listing } = action.payload;
      state.listings.every(({ _id }, i) => {
        if (id === _id) {
          state.listings[i] = listing;
          return false;
        }
        return true;
      });
    },
    setListing: (state, action) => {
      state.listing = action.payload;
    },
    setListings: (state, action) => {
      const {count,listings} = action.payload;
      state.listings = listings;
      state.count = count;
      state.refreshLoader = false;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters.page = action.payload;
    },
    refresh: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
      state.refreshLoader = true;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
    deleteListing: (state, action) => {
      const id = action.payload;
      state.listings.every(({ _id }, i) => {
        if (id === _id) {
          state.count -= 1;
          state.listings.splice(i, 1);
          return false;
        }
        return true;
      });
    },
  },
});

const listingReducer = listingSlice.reducer;

export const listingActions = listingSlice.actions;
export default listingReducer;
