import http from "./http.service";
import Promisable from "./promisable.service";
import { NavigateFunction } from "react-router-dom";
import { loaderActions } from "redux/slices/loader";
import { getAppDispatch } from "utils/dispatch.util";
import { formLoaderActions } from "redux/slices/formLoader";
import { listingActions } from "redux/slices/listing";
import { modalActions } from "redux/slices/modal";
import ImageService from "./image.service";

const url = "admin/listing";

const ListingService = {
  addListing: async (id: any, values: any, navigate?: NavigateFunction) => {
    const data = { ...values };
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();
    // if (data.image) {
    //   data.businessLogo = await ImageService.uploadFile(data.image);
    //   delete data["image"];
    // }
    // if (data.gallery) {
    //   const filteredGallery = data.gallery.filter(
    //     (element: any) => element.image
    //   );
    //   if (filteredGallery?.length > 0) {
    //     data.gallery = await Promise.all(
    //       filteredGallery.map(async (element: any) => {
    //         console.log({element})
    //         return await ImageService.uploadFile(element.image);
    //       })
    //     );
    //   } else delete data["gallery"];
    // }

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/register?userId=${id}`, data)
    );

    if (success) {
      const { listing } = success?.data?.data || {};
      // dispatch?.(listingActions.addListing(listing));
      navigate?.("/listings");
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
  updateListing: async (
    id: string,
    values: any,
    navigate?: NavigateFunction
  ) => {
    const data = { ...values };

    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();
    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/${id}`, data)
    );

    if (success) {
      const { listing, user } = success?.data?.data || {};
      dispatch?.(listingActions.setListing({ listing, user }));
      dispatch?.(listingActions.updateListing({ id, listing, user }));
      navigate?.(`/listing-details/${id}`);
    }
    dispatch?.(modalActions.setLoading(false));
    dispatch?.(modalActions.closeModal());
    dispatch?.(listingActions.setLoading(false));
    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
  getListing: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(loaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const { listing, user } = success?.data?.data || {};
      dispatch?.(listingActions.setListing({ listing, user }));
    } else dispatch?.(listingActions.setListing({ data: "Not Found" }));

    dispatch?.(loaderActions.setLoading(false));
    return [success, error];
  },
  getListings: async (data?: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(listingActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}`, data)
    );

    if (success) {
      const { totalCount, listings } = success?.data?.data || {};
      dispatch?.(listingActions.setListings({ count: totalCount, listings }));
    }

    dispatch?.(listingActions.setLoading(false));
    return [success, error];
  },

  updateListingStatus: async (id: any, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.patch(`${url}/update-status/${id}`, data)
    );

    if (success) {
      const { listing, user } = success?.data?.data || {};
      dispatch?.(modalActions.closeModal());
      dispatch?.(listingActions.updateListing({ id, listing, user }));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  updateListingRank: async (id: any, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/${id}`, data)
    );

    if (success) {
      const { listing, user } = success?.data?.data || {};
      dispatch?.(modalActions.closeModal());
      dispatch?.(listingActions.updateListing({ id, listing, user }));
      dispatch?.(listingActions.setListing({ listing, user }));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
  deleteListing: async (id: string,navigate?:NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.delete(`${url}/${id}`)
    );

    if (success) {
      dispatch?.(listingActions.deleteListing(id));
      dispatch?.(modalActions.closeModal());
      navigate?.('/listings')
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
  getOptions: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/options`)
    );

    if (success) {
      const { listings } = success?.data?.data || {};
      dispatch?.(listingActions.setOptions(listings));
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
};

export default ListingService;
