import http from "./http.service";
import Promisable from "./promisable.service";
import { authActions } from "redux/slices/auth";
import { getAppDispatch } from "utils/dispatch.util";

const url = "/user/auth";

const AuthService = {
  login: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(authActions.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/login`, data)
    );

    if (success) {
      console.log("success",success)
      const { user, jwtToken } = success?.data?.data||{};
      localStorage.setItem("token", `Bearer ${jwtToken}`);
      dispatch?.(authActions.setUser(user));
    }

    dispatch?.(authActions.setLoading(false));
    return [success, error];
  },

  logout: () => {
    const dispatch = getAppDispatch();

    localStorage.removeItem("token");

    dispatch?.(authActions.logout());
    dispatch?.(authActions.setUser(null));
    window.location.href='/'
  },
};
export default AuthService;
