import FileUpload from "components/atoms/FileUpload";
import { useState } from "react";
import { useAppDispatch } from "redux/hooks";
import { loaderActions } from "redux/slices/loader";
import ImageService from "services/image.service";
export default function FileUploadRedux({
  input,
  handleBlur,
  handleFocus,
  FileUploadProps,
  meta,
  ...rest
}: any) {
  const { error = "", touched = "", invalid = "" } = meta || {};
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  return (
    <FileUpload
      {...FileUploadProps}
      {...rest}
      {...input}
      loading={loading}
      helperText={touched && invalid && error}
      error={touched && invalid && error && true}
      onChange={async (file: any) => {
        if (file) {
          if (file.error) return input.onChange(file);
          setLoading(true);
          dispatch(loaderActions.setImgLoading(true));
          let res = await ImageService.uploadFile({
            name: file.name,
            type: file.type,
            blob: URL.createObjectURL(file),
          },rest.folder);
          setLoading(false);
          dispatch(loaderActions.setImgLoading(false));
          input.onChange(res);
        } else input.onChange("");
      }}
      onBlur={(e) => {
        handleBlur?.(e);
        e.preventDefault();
      }}
      onFocus={(e) => {
        handleFocus?.(e);
        e.preventDefault();
      }}
    />
  );
}
