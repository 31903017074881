import { useAppSelector } from "redux/hooks";
import UpdateListingStatusForm from "components/templates/UpdateListingStatusForm";
import ListingService from "services/listings.service";
import ReviewService from "services/reviews.service";
import ComplaintService from "services/complaint.service";

export default function UpdateListingStatus() {
  const { _id ,type} = useAppSelector((state) => state.modal?.data);
  const handleSubmit = async (values: any) => {
    let data = { ...values };
    if(type==='Review') ReviewService.updateReviewStatus(_id, {adminApproved:data.currentStatus==='approved'?true:false,...data})
   else if(type==='complaint')ComplaintService.updateComplaintStatus(_id, {adminApproved:data.currentStatus==='approved'?true:false,...data})
    
    else ListingService.updateListingStatus(_id, data);
   
  };
  return (
    <div>
      <h3>Update {type||'Listing'} Status</h3>
      <UpdateListingStatusForm  onSubmit={handleSubmit} />
    </div>
  );
}
