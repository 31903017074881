import { config } from "config";
import { complaintState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const default_page_size = config.PAGE_SIZE;

const initialState: complaintState = {
  tab: 0,
  refresh: 0,
  refreshLoader: false,
  complaints: [],
  recentComplaints: [],
  rejectedComplaints: [],
  complaint: null,
  loading: true,
  count: 0,
  complaintOptions: [],
  current_filters: {},
  filters: { page: 1, pageSize: default_page_size },
};

export const complaintSlice = createSlice({
  name: "complaint",
  initialState,
  reducers: {
    clear: (state) => {
      state.complaintOptions = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTab: (state, action: PayloadAction<number>) => {
      state.tab = action.payload;
      state.refreshLoader = true;
    },
    addComplaint: (state, action) => {
      state.complaints.unshift(action.payload);
    },
    refresh: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
    },

    updateComplaintStatus: (state, action: PayloadAction<any>) => {
      state.refresh += 1;
      state.refreshLoader = true;
    },
    setComplaint: (state, action) => {
      state.complaint = action.payload;
    },
    setComplaints: (state, action) => {
      const { count, complaints } = action.payload;
      state.complaints = complaints;
      state.count = count;
      state.refreshLoader = false;
    },

    setPage: (state, action: PayloadAction<number>) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters.page = action.payload;
    },

    resetPage: (state) => {
      state.filters.page = 1;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
      state.refreshLoader = true;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
  },
});

const complaintReducer = complaintSlice.reducer;

export const complaintActions = complaintSlice.actions;
export default complaintReducer;
