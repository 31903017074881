import { Grid } from "@mui/material";
import Button from "components/atoms/Button";
import { required } from "utils/validate.util";
import { modalActions } from "redux/slices/modal";
import { Field, reduxForm } from "redux-form";
import { useAppDispatch } from "redux/hooks";
import InputRedux from "components/molecules/InputRedux";

function AddCategoryForm({ form, handleSubmit }: any) {
  const dispatch = useAppDispatch();

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Field
              name={"title"}
              label={"Title"}
              validate={[required]}
              component={InputRedux}
            />
          </Grid>
        </Grid>
        <br />
        <Button
          variant="outlined"
          onClick={() => dispatch(modalActions.closeModal())}
          sx={{ marginRight: "10px" }}
        >
          Cancel
        </Button>
        <Button type="submit" variant="contained">
          Submit
        </Button>
      </form>
    </div>
  );
}

export default reduxForm({ form: "AddCategoryForm" })(AddCategoryForm);
