import { useAppSelector } from "redux/hooks";
import CategoryService from "services/category.service";
import { useState } from "react";
import CircleLoader from "components/atoms/CircleLoader";
import { Button } from "@mui/material";
import BaseCheckbox from "components/atoms/BaseCheckbox";
import ListingService from "services/listings.service";

export default function AIReview() {
  const [loading, setLoading] = useState(false)
  const [generate, setGenerate] = useState(false)

  const { _id ,generateReviews} = useAppSelector((state) => state.modal?.data);
  const handleSubmit = async (values: any) => {
    setLoading(true)
    await ListingService.updateListing(_id, {generateReviews:generate});
    setLoading(false)

  };
  return (
    <div>
      {loading&&<CircleLoader />}
      <h3>Generate Reviews by AI</h3>
      <BaseCheckbox label={'Generate'} defaultChecked={generateReviews} onChange={(event)=>setGenerate(event.target.checked)}/>
      <p>The AI will generate two reviews every two days based on the description and service provided by the listing</p>
      <br />
        <Button
          color="secondary"
          disableElevation
          size="large"
          variant="contained"
          fullWidth
          onClick={handleSubmit}
          type="submit"
        >
          Submit
        </Button>
    </div>
  );
}
