import { useAppDispatch, useAppSelector } from "redux/hooks";
import LabelForm from "./LabelForm";
import ListingService from "services/listings.service";
import { useEffect } from "react";
import { change } from "redux-form";

const AddLabel = () => {
  const data = useAppSelector((state) => state.modal.data);
  const dispatch = useAppDispatch();
  const { label = [], _id } = data || {};
  useEffect(() => {
    if (label?.length > 0) {
      label.forEach((element: any) => {
        dispatch(change("LabelForm", element, true));
      });
    }
  }, [dispatch, label]);

  const onSubmit = (values: any) => {
    let labels = [];
    for (const key in values) {
      if (values[key]) {
        labels.push(key);
      }
    }
    ListingService.updateListing(_id, { labels });
  };
  return (
    <div>
      <h3>{label?.length > 0 ? "Update" : "Add"} Label</h3>
      <LabelForm onSubmit={onSubmit} />
    </div>
  );
};

export default AddLabel;
