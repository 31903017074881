import CheckboxRedux from "components/molecules/CheckboxRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields";

export { default } from "./LabelForm";

export const fields: ReduxFormField[] = [
	{
		name: "Movers Choice",
		label: "Movers Choice",
		component: CheckboxRedux,
		cellProps: { md: 6 },
	},
	{
		name: "Premium",
		label: "Premium",
		component: CheckboxRedux,
		cellProps: { md: 6 },
	},
	{
		name: "Top Rated",
		label: "Top Rated",
		component: CheckboxRedux,
		cellProps: { md: 6 },
	},
	{
		name: "Low Rated",
		label: "Low Rated",
		component: CheckboxRedux,
		cellProps: { md: 6 },
	},
];


