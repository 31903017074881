import { Grid } from "@mui/material";
import Button from "components/atoms/Button";
import { modalActions } from "redux/slices/modal";
import { Field, change, reduxForm } from "redux-form";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import useEffectOnce from "hooks/useEffectOnce";
import InputRedux from "components/molecules/InputRedux";
import SelectRedux from "components/molecules/SelectRedux";
import { required } from "utils/validate.util";

function UpdateListingRankForm({ form, handleSubmit }: any) {
  const dispatch = useAppDispatch();
  const { rank, rankPage,type } = useAppSelector((state) => state.modal?.data);
  const data = useAppSelector(
    (state) => state.form.UpdateListingRankForm?.values
  );

  useEffectOnce(() => {
    if (!rank) return;
    dispatch(change(form, "rank", rank));
    dispatch(change(form, "rankPage", rankPage));
  });
  let options = [
    { label: "Moving Company", value: "Moving Company" },
    { label: "Moving Brokers", value: "Moving Brokers" },
    { label: "Moving Labor", value: "Moving Labor" },
  ];

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{paddingTop:type!=='featureRank'&&rank?"0px":"16px"}} >
           {rank&&type!=='featureRank'&& <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "end", textAlign: "end" }}
            >
              <Button
                onClick={() => {
                  dispatch(change(form, "rank", null));
                  dispatch(change(form, "rankPage", null));
                }}
              >
                Remove
              </Button>
            </Grid>}
            <Field name="rank" label="Rank" component={InputRedux} />
          </Grid>
          {type!=='featureRank'&& <Grid item xs={12}>
            <Field
              name="rankPage"
              label="Page"
              component={SelectRedux}
              validate={data?.rank && [required]}
              SelectProps={{ options }}
            />
          </Grid>}
        </Grid>
        <br />
        <Button
          variant="outlined"
          onClick={() => dispatch(modalActions.closeModal())}
          sx={{ marginRight: "10px" }}
        >
          Cancel
        </Button>
        <Button type="submit" variant="contained">
          Submit
        </Button>
      </form>
    </div>
  );
}

export default reduxForm({ form: "UpdateListingRankForm" })(
  UpdateListingRankForm
);
