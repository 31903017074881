import CircleLoader from "components/atoms/CircleLoader";
import AddCategoryForm from "components/templates/AddCategoryForm";
import { useState } from "react";
import CategoryService from "services/category.service";

export default function AddCategory() {
  const [loading, setLoading] = useState(false)
  const handleSubmit = async (values: any) => {
    let data = { ...values };
    setLoading(true)
   await CategoryService.addCategory(data);
    setLoading(false)

  };
  return (
    <div>
      <h3>Add Category</h3>
      {(loading ) && <CircleLoader />}

      <AddCategoryForm onSubmit={handleSubmit} />
    </div>
  );
}
