import { SelectOption } from "components/atoms/Select";

export { default } from "./UpdateListingStatusForm";
export const statuses: SelectOption[] = [
  { value: "approved", label: "Approved" },
  { value: "pending", label: "Pending" },
];

export const ReviewStatuses: SelectOption[] = [
  { value: "approved", label: "Approved" },
  { value: "rejected", label: "Rejected" },
];
