import "./Header.css";
import { links } from ".";
import { Fragment } from "react";
import { Grid } from "@mui/material";
import logo from "assets/logo-light.png";
import { useAppSelector } from "redux/hooks";
import AuthService from "services/auth.service";
import BasicMenu from "components/atoms/BasicMenu";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const user = useAppSelector((state) => state.auth.user);

  if (!user || pathname === "/") return <></>;

  return (
    <header>
      <div className="navbar">
        <Grid container alignItems="center">
          <Grid item xs={12} lg={2}>
            <Link to="/bookings">
              <div className="logo">
                <img
                  src={logo}
                  alt="logo"
                  loading="lazy"
                  width={"200px"}
                  height={"100%"}
                />
              </div>
            </Link>
          </Grid>
          <Grid item xs={12} lg={10}>
            <ul>
              {links.map(({ to, text, active, options }, i) => (
                <Fragment key={i}>
                  {options ? (
                    <li>
                      <BasicMenu
                        avatar={false}
                        color="#FFFFFF"
                        list={options.map(({ value, label }) => ({
                          text: label,
                          onClick: () => navigate(value),
                        }))}
                      >
                        {text}
                      </BasicMenu>
                    </li>
                  ) : (
                    <li key={i}>
                      <Link
                        to={to}
                        className={
                          pathname.includes(active) &&
                          !pathname.includes("invoice")
                            ? "active"
                            : ""
                        }
                      >
                        {text}
                      </Link>
                    </li>
                  )}
                </Fragment>
              ))}
              <li>
                <div style={{ padding: "10px 0" }}>
                  <BasicMenu
                    avatar={true}
                    list={[
                      // {
                      //   text: "Events",
                      //   onClick: () => navigate("/events"),
                      // },
                      // {
                      //   text: "Support",
                      //   onClick: () => navigate("/contacts"),
                      // },
                      // {
                      //   text: "Commission",
                      //   onClick: () => navigate("/commission"),
                      // },
                      // {
                      //   text: "Booking Ratings",
                      //   onClick: () => navigate("/ratings"),
                      // },
                      // {
                      //   text: "Employee Payroll",
                      //   onClick: () => navigate("/payroll"),
                      // },
                      // {
                      //   text: "Partner Invoices",
                      //   onClick: () => navigate("/partner-invoices"),
                      // },
                      // {
                      //   text: "Partner Requests",
                      //   onClick: () => navigate("/partner-requests"),
                      // },
                      // {
                      //   text: "Company Invoices",
                      //   onClick: () => navigate("/company-invoices"),
                      // },
                      // {
                      //   text: "Company Requests",
                      //   onClick: () => navigate("/company-requests"),
                      // },
                      // {
                      //   text: "Reported Problems",
                      //   onClick: () => navigate("/reported-problems"),
                      // },
                      // {
                      //   text: "Search History",
                      //   onClick: () => navigate("/search-history"),
                      // },
                      {
                        text: "Logout",
                        onClick: () => AuthService.logout(),
                      },
                    ]}
                  >
                    {user.fullName?.charAt()}
                  </BasicMenu>
                </div>
              </li>
            </ul>
          </Grid>
        </Grid>
      </div>
    </header>
  );
}
