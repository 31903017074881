import { lazy } from "react";

const Listings = lazy(() => import("pages/Listings"));
const ListingDetails = lazy(() => import("pages/Listings/ListingDetails"));
const AddListing = lazy(() => import("pages/AddListing"));
const ListingSettings = lazy(() => import("pages/ListingSettings"));

const Users = lazy(() => import("pages/Users/UsersList"));
const UserDetails = lazy(() => import("pages/UserDetails"));

const Categories = lazy(() => import("pages/Categories/CategoryList"));
const Reviews = lazy(() => import("pages/Reviews"));
const Complaints = lazy(() => import("pages/Complaints"));

const AddReview = lazy(() => import("pages/AddReview"));
const Enquires = lazy(() => import("pages/Enquires/EnquireList"));
const EnquireDetails = lazy(() => import("pages/EnquireDetails"));

const Login = lazy(() => import("pages/Login"));
const Contacts = lazy(() => import("pages/Contacts"));
const Realtor = lazy(() => import("pages/Realtor"));
const AddRealtor = lazy(() => import("pages/AddRealtor"));


const Certificates = lazy(() => import("pages/Certificates"));
const AddCertificate = lazy(() => import("pages/AddCertificate"));

export { default } from "./AppRoutes";

export interface IRoute {
  path: string;
  element: JSX.Element;
}

export const public_routes: IRoute[] = [{ path: "/", element: <Login /> }];

export const private_routes: IRoute[] = [
  { path: "/listings", element: <Listings /> },
  { path: "/listing-details/:id", element: <ListingDetails /> },
  { path: "/add-listing", element: <AddListing /> },
  { path: "/update-listing/:id", element: <AddListing /> },
  { path: "/listing-settings/:id", element: <ListingSettings /> },

  { path: "/users", element: <Users /> },
  { path: "/add-user", element: <UserDetails /> },
	{ path: "/user-details/:id", element: <UserDetails /> },

  { path: "/categories", element: <Categories /> },
  { path: "/complaints", element: <Complaints /> },

  
  { path: "/reviews", element: <Reviews /> },
  { path: "/add-review/:id", element: <AddReview /> },
  { path: "/inquiries", element: <Enquires /> },
  { path: "/inquiries-details/:id", element: <EnquireDetails /> },
  { path: "/contacts", element: <Contacts /> },
  { path: "/realtor", element: <Realtor /> },
  { path: "/add-realtor", element: <AddRealtor /> },

  

  { path: "/certificates", element: <Certificates /> },
  { path: "/add-certificate", element: <AddCertificate /> },
  { path: "/update-certificate/:id", element: <AddCertificate /> },

];
