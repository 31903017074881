import ToasterService from "utils/toaster.util";
import http from "./http.service";
import Promisable from "./promisable.service";

const ImageService = {
  getImageFileFromBlob: async ({ blob, name, type }: any) => {
    const response = await fetch(blob);
    const data = await response.blob();
    return new File([data], name, {
      type: response.headers.get("content-type") || type,
    });
  },

  uploadFile: async (data: any,folder?:any) => {
    let img = await ImageService.getImageFileFromBlob(data);
    http.setJWT();
    http.setMultiPart();
    let formData = new FormData();
    formData.append("file", img);
    const [success, error]: any = await Promisable.asPromise(
      http.post(`/file/upload?folder=${folder}`, formData)
    );
    if (success && success?.data?.data?.url) {
      return success?.data?.data?.url[0].path;
    } else ToasterService.showError(`${error?.errorMessage}`);
  },

  getIcons: async (type?:any) => {
    http.setJWT();
    const [success, error]: any = await Promisable.asPromise(
      http.get(`/file/icons`)
    );
    if (success && success?.data?.data?.icons) {
      let icons=success?.data?.data?.icons?.filter((item:any)=>item.type===type)
      return icons?.map((item:any)=>item.key)
    } else ToasterService.showError(`${error?.errorMessage}`);
  },
};

export default ImageService;
