import http from "./http.service";
import Promisable from "./promisable.service";
import { NavigateFunction } from "react-router-dom";
import { loaderActions } from "redux/slices/loader";
import { getAppDispatch } from "utils/dispatch.util";
import { formLoaderActions } from "redux/slices/formLoader";
import { complaintActions } from "redux/slices/complaint";
import { modalActions } from "redux/slices/modal";
import ImageService from "./image.service";

const url = "admin/complaint";

const ComplaintService = {
  addComplaint: async (id: any, data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();
    if (data.image) {
      data.image = await ImageService.uploadFile(data.image);
    }
    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/add-complaint/${id}`, data)
    );

    if (success) {
      // const { complaint } = success?.data?.data||{};
      console.log("success", success);

      // dispatch?.(complaintActions.addComplaint(complaint));
      navigate?.(`/listing-details/${id}`);
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  getComplaint: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(loaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const { complaint } = success?.data?.data || {};
      dispatch?.(complaintActions.setComplaint(complaint));
    } else dispatch?.(complaintActions.setComplaint({ data: "Not Found" }));

    dispatch?.(loaderActions.setLoading(false));
    return [success, error];
  },
  getComplaints: async (data: any, type: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(complaintActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/getAll?type=${type}`, data)
    );

    if (success) {
      const { complaints, count } = success?.data?.data || {};
      dispatch?.(complaintActions.setComplaints({ complaints, count }));
    }

    dispatch?.(complaintActions.setLoading(false));
    return [success, error];
  },

  updateComplaintStatus: async (
    id: any,
    data: any,
    navigate?: NavigateFunction
  ) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.patch(`${url}/${id}`, data)
    );

    if (success) {
      dispatch?.(modalActions.closeModal());
      dispatch?.(
        complaintActions.updateComplaintStatus({
          id,
          currentStatus: data.currentStatus,
        })
      );
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
};

export default ComplaintService;
