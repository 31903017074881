import { ReviewStatuses, statuses } from ".";
import { Grid } from "@mui/material";
import Button from "components/atoms/Button";
import { required } from "utils/validate.util";
import { modalActions } from "redux/slices/modal";
import { Field, change, reduxForm } from "redux-form";
import SelectRedux from "components/molecules/SelectRedux";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import useEffectOnce from "hooks/useEffectOnce";

function UpdateListingStatusForm({form, handleSubmit}: any) {
  const dispatch = useAppDispatch();
  const { currentStatus ,type} = useAppSelector((state) => state.modal?.data);
  const options = ['Review','complaint'].includes(type)?ReviewStatuses: statuses;

  useEffectOnce(() => {
    if (!currentStatus) return;

    dispatch(change(form, "currentStatus", currentStatus));
  });

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Field
              name="currentStatus"
              label="Status"
              component={SelectRedux}
              validate={[required]}
              SelectProps={{ options }}
            />
          </Grid>
        </Grid>
        <br />
        <Button
          variant="outlined"
          onClick={() => dispatch(modalActions.closeModal())}
          sx={{ marginRight: "10px" }}
        >
          Cancel
        </Button>
        <Button type="submit" variant="contained">
          Submit
        </Button>
      </form>
    </div>
  );
}

export default reduxForm({ form: "UpdateListingStatusForm" })(
  UpdateListingStatusForm
);
