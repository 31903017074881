export { default, modalActions, modalSlice } from "./modalSlice";

export enum MODAL {
  DELETE_USER = "DELETE_USER",
  ADD_PAYMENT = "ADD_PAYMENT",
  HANDLE_STATUS = "HANDLE_STATUS",
  CHILDREN_SEATS = "CHILDREN_SEATS",
  DISABLE_DRIVER = "DISABLE_DRIVER",
  CONFIRMATION_FORM = "CONFIRMATION_FORM",
  CONFIRMATION_MODAL = "CONFIRMATION_MODAL",
  UPDATE_BOOKING_BILLING_ADDRESS = "UPDATE_BOOKING_BILLING_ADDRESS",
  EDIT_ADDRESS = "EDIT_ADDRESS",
  UPDATE_LISTING_STATUS = "UPDATE_LISTING_STATUS",
  UPDATE_LISTING_RANK = "UPDATE_LISTING_RANK",
  VIEW_REVIEW = "VIEW_REVIEW",

  EXPORT_TABLE_OPTIONS = "EXPORT_TABLE_OPTIONS",
  ADD_CATEGORY = "ADD_CATEGORY",
  UPDATE_CATEGORY_STATUS="UPDATE_CATEGORY_STATUS",
  ICON_GALLERY="ICON_GALLERY",
  LABEL="LABEL",
  SEND_EMAIL='SEND_EMAIL',
  DISPLAY_IFRAME='DISPLAY_IFRAME',
  AI_REVIEW='AI_REVIEW'
}

export type ModalType = "" | keyof typeof MODAL;

export interface OpenModalState {
  data: any;
  width: any;
  type: ModalType;
  loading?: boolean;
}

export type ModalState = OpenModalState & {
  open: boolean;
};
