import { SelectOption } from "components/atoms/Select";

export { default } from "./UpdateCategoryStatusForm";
export const statuses: SelectOption[] = [
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
];

export const CategoryStatuses: SelectOption[] = [
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
];
