import http from "./http.service";
import { reset } from "redux-form";
import Promisable from "./promisable.service";
import { certificateActions } from "redux/slices/certificate";
import { getAppDispatch } from "utils/dispatch.util";
import { modalActions } from "redux/slices/modal/modalSlice";
import ObjectService from "utils/object.util";
import { NavigateFunction } from "react-router-dom";
import { formLoaderActions } from "redux/slices/formLoader";
import ToasterService from "utils/toaster.util";

const url = "/admin/certificate";

const CertificateService = {
  getCertificate: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(certificateActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const { certificate } = success?.data?.data || {};
      dispatch?.(certificateActions.setCertificate(certificate));
      dispatch?.(certificateActions.setLoading(false));
    } else dispatch?.(certificateActions.setCertificate({ data: "Not Found" }));

    dispatch?.(certificateActions.setLoading(false));
    return [success, error];
  },
  getSearchCertificates: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(certificateActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}`, data)
    );

    if (success) {
      const { certificates } = success?.data?.data || {};
      const count = certificates.length || 0;
      dispatch?.(
        certificateActions.setSearchCertificates({ certificates, count })
      );
      dispatch?.(certificateActions.setLoading(false));
    } else dispatch?.(certificateActions.setCertificate({ data: "Not Found" }));

    dispatch?.(certificateActions.setLoading(false));
    return [success, error];
  },

  getCertificates: async (data?: any) => {
    const dispatch = getAppDispatch();

    if (data?.all) dispatch?.(modalActions.setLoading(true));
    else dispatch?.(certificateActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/get`, data)
    );

    if (success) {
      const { certificates, totalCount } = success?.data?.data || {};
      dispatch?.(
        certificateActions.setCertificates({ certificates, count: totalCount })
      );
    }

    if (data?.all) dispatch?.(modalActions.setLoading(false));
    else dispatch?.(certificateActions.setLoading(false));
    return [success, error];
  },
  sendCertificate: async (data?: any) => {
    const dispatch = getAppDispatch();

    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.patch(`${url}/send-email`, data)
    );

    dispatch?.(modalActions.setLoading(false));
    dispatch?.(modalActions.closeModal());

    return [success, error];
  },
  getAll: async (data?: any) => {
    const dispatch = getAppDispatch();

    dispatch?.(certificateActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}`, data)
    );

    if (success) {
      const { certificates } = success?.data?.data || {};
      if (certificates?.length > 0) {
        let list = certificates?.map((item: any) => ({
          value: item._id,
          label: item.fullName,
        }));
        dispatch?.(certificateActions.setOptions(list));
      }
    }
    dispatch?.(certificateActions.setLoading(false));
    return [];
  },
  addCertificate: async (data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}`, data)
    );

    if (success) {
      const { certificate } = success?.data?.data || {};
      dispatch?.(certificateActions.addCertificate(certificate));
      navigate?.("/certificates");
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  updateCertificate: async (
    id: string,
    data: any,
    navigate?: NavigateFunction
  ) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.patch(`${url}/update/${id}`, data)
    );
    if (success) {
      const { certificate } = success?.data?.data || {};
      dispatch?.(certificateActions.setCertificate(certificate));
      navigate?.("/certificates");
    }
    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },

  deleteCertificate: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.delete(`${url}/${id}`)
    );

    if (success) {
      dispatch?.(certificateActions.deleteCertificateById(id));
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  updateBillingAddress: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/updatebillingaddress/${id}`, data)
    );

    if (success) {
      const { certificate } = success?.data?.data || {};
      dispatch?.(certificateActions.setCertificate(certificate));
    }

    return [success, error];
  },

  addAdminNotes: async (id: string, data: any) => {
    const dispatch = getAppDispatch();

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/admin-notes/${id}`, data)
    );

    if (success) {
      const { certificate } = success?.data?.data || {};
      dispatch?.(certificateActions.setCertificate(certificate));
    }

    return [success, error];
  },

  addPoints: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(certificateActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`yelo-points`, data)
    );

    if (success) {
      const { certificate } = success?.data?.data || {};
      dispatch?.(certificateActions.setCertificate(certificate));
      dispatch?.(certificateActions.setLoading(false));
      dispatch?.(reset("AddCertificatePointsForm"));
    }

    dispatch?.(certificateActions.setLoading(false));
    return [success, error];
  },
};

export default CertificateService;
