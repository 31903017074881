import { useAppSelector } from "redux/hooks";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { config } from "config";

export default function ViewReview() {
  const data = useAppSelector((state) => state.modal.data);
  let attachments = data?.attachments;
  const images = attachments?.map((item: string) => ({
    original: config.API_URL + item,
    thumbnail: config.API_URL + item,
    lazyLoad:true,
    originalHeight:500,
    originalWidth:500,
    thumbnailWidth:120,
    thumbnailHeight:70,
    thumbnailLoading:'lazy'
  }));

  return (
    <div>
      <h3>{data.title}</h3>
      {data.comment&&<p>{data.comment}</p>}
      {attachments?.length>0 && (
        <div style={{width:"100%"}}>
          <ImageGallery items={images} showPlayButton={false} />
        </div>
      )}
    </div>
  );
}
